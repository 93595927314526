import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "images/design-illustration-PPC-4.webp";
import macHeroScreenshotImageSrc from "images/design-illustration-PPC-3.webp";
import prototypeIllustrationImageSrc from "images/design-illustration-PPC-2.webp";
import TabGrid from "components/cards/TabCardGrid.js";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { useLanguage } from "../helpers/LanguageContext";
import calendar from "images/calendar.webp";

export default () => {
  const { parsedTranslation, translate } = useLanguage();
  const Subheading = tw.span`uppercase tracking-widest font-bold text-blue-500`;
  const HighlightedText = tw.span`text-blue-500`;
  const HighlightedTextWithBG = tw.span`bg-blue-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const tabs = {
    [translate("Monthly Fee")]: [
      {
        imageSrc: calendar,
        title: parsedTranslation("monthly_fee_service"),
        content: parsedTranslation("monthly_fee_service_description"),
        list: [
          {
            question: translate("update_operating_system_and_software")
              .question,
            answer: translate("update_operating_system_and_software").answer,
          },
          {
            question: translate("run_antivirus_and_antimalware_scans").question,
            answer: translate("run_antivirus_and_antimalware_scans").answer,
          },
          {
            question: translate("clean_disk_space").question,
            answer: translate("clean_disk_space").answer,
          },
          {
            question: translate("backup_data").question,
            answer: translate("backup_data").answer,
          },
          {
            question: translate("check_hardware_health").question,
            answer: translate("check_hardware_health").answer,
          },
          {
            question: translate("defragment_hard_drive").question,
            answer: translate("defragment_hard_drive").answer,
          },
          {
            question: translate("manage_startup_programs").question,
            answer: translate("manage_startup_programs").answer,
          },
          {
            question: translate("clean_hardware_components").question,
            answer: translate("clean_hardware_components").answer,
          },
          {
            question: translate("ensure_network_security").question,
            answer: translate("ensure_network_security").answer,
          },
          {
            question: translate("educate_users").question,
            answer: translate("educate_users").answer,
          },
        ],
        price: "$99.99",
        priceHeading: parsedTranslation("up_to_5_devices"),
        priceDescription: parsedTranslation("additional_device_price"),
      },
    ],
  };

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>{parsedTranslation("features")}</Subheading>}
        heading={<>{parsedTranslation("features_title")}</>}
        description={<>{parsedTranslation("features_description")}</>}
      />
      <MainFeature
        subheading={
          <Subheading>{parsedTranslation("quality_of_work")}</Subheading>
        }
        heading={<>{parsedTranslation("quality_of_work_title")}</>}
        description={parsedTranslation("quality_of_work_description")}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      />
      <FeatureWithSteps
        subheading={<Subheading>{parsedTranslation("steps")}</Subheading>}
        heading={<>{parsedTranslation("steps_title")}</>}
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <MainFeature2
        subheading={<Subheading>{parsedTranslation("values")}</Subheading>}
        heading={<>{parsedTranslation("values_title")}</>}
        description={parsedTranslation("values_description")}
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: parsedTranslation("affordable"),
            description: parsedTranslation("affordable_description"),
            iconContainerCss: tw`bg-green-300 text-green-800`,
          },
          {
            Icon: BriefcaseIcon,
            title: parsedTranslation("professionalism"),
            description: parsedTranslation("professionalism_description"),
            iconContainerCss: tw`bg-red-300 text-red-800`,
          },
        ]}
      />
      <TabGrid
        heading={
          <>
            {translate("checkout_our")}{" "}
            <HighlightedTextWithBG>
              {translate("prices")}.
            </HighlightedTextWithBG>
          </>
        }
        tabs={tabs}
      />
      <FAQ
        subheading={<Subheading>{parsedTranslation("faqs")}</Subheading>}
        heading={<>{parsedTranslation("faqs_title")}</>}
        description={parsedTranslation("faqs_description")}
        faqs={[
          {
            question: translate("computer_needs_repair").question,
            answer: translate("computer_needs_repair").answer,
          },
          {
            question: translate("typical_repair_duration").question,
            answer: translate("typical_repair_duration").answer,
          },
          {
            question: translate("repair_warranty").question,
            answer: translate("repair_warranty").answer,
          },
          {
            question: translate("devices_repaired").question,
            answer: translate("devices_repaired").answer,
          },
          {
            question: translate("data_recovery").question,
            answer: translate("data_recovery").answer,
          },
          {
            question: translate("prevent_future_issues").question,
            answer: translate("prevent_future_issues").answer,
          },
        ]}
      />
      <GetStarted
        heading={parsedTranslation("get_started")}
        email="hello@pcprecisionrepairs.com"
        phone="(305) 301-5802"
        primaryLinkText={parsedTranslation("contact_us")}
        primaryLinkUrl="/contact-us"
      />
      <Footer />
    </AnimationRevealPage>
  );
};
