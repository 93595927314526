import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import FAQ from "components/faqs/SingleCol.js";
import { useLanguage } from "../helpers/LanguageContext";

const Subheading = tw.span`uppercase tracking-widest font-bold text-blue-500`;
const HighlightedText = tw.span`text-blue-500`;

export default () => {
  const { translate, parsedTranslation } = useLanguage();

  return (
    <AnimationRevealPage>
      <Header />
      <ContactUsForm
        subheading={parsedTranslation("contact_us")}
        heading={parsedTranslation("contact_us_title")}
      />
      <FAQ
        subheading={<Subheading>{parsedTranslation("faqs")}</Subheading>}
        heading={<>{parsedTranslation("faqs_title")}</>}
        description={parsedTranslation("faqs_description")}
        faqs={[
          {
            question: translate("contact_team").question,
            answer: translate("contact_team").answer,
          },
          {
            question: translate("business_hours").question,
            answer: translate("business_hours").answer,
          },
          {
            question: translate("support_information").question,
            answer: translate("support_information").answer,
          },
          {
            question: translate("response_time").question,
            answer: translate("response_time").answer,
          },
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
