// LanguageContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import translationData from "../data/translations.json";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "en"
  );

  // Function to translate text based on the current language
  const translate = (key) => {
    return translationData[language][key] || key; // Return translated text or original key if translation not found
  };

  const parsedTranslation = (key) => {
    const translation = translate(key);
    // Use dangerouslySetInnerHTML to render HTML-like structure
    return <div dangerouslySetInnerHTML={{ __html: translation }} />;
  };

  // Update localStorage when language changes
  useEffect(() => {
    localStorage.setItem("language", language);
  }, [language]);

  return (
    <LanguageContext.Provider
      value={{ language, setLanguage, translate, parsedTranslation }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
