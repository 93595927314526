import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import EnglishFlag from "../../images/united-states-flag.png";
import SpanishFlag from "../../images/spain-flag.png";
import logo from "../../images/logo-PPC-2.svg";
import { useLanguage } from "../../helpers/LanguageContext.js";

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto
`;
export const NavLinks = tw.div`flex items-center gap-2`;
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-blue-500 hocus:text-blue-500

`;
export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-blue-500 text-gray-100
  hocus:bg-blue-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;
export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
    filter: invert(20%) sepia(8%) saturate(4114%) hue-rotate(176deg) brightness(98%) contrast(89%);
  }
  /*&:hover img {
    filter: invert(55%) sepia(74%) saturate(1172%) hue-rotate(182deg)
      brightness(91%) contrast(93%);
  }*/
`;
export const DesktopNavLinks = tw.nav`
  flex flex-1 justify-center items-center flex-wrap sm:justify-between gap-4
`;
const Image = tw.img`w-16 h-8 rounded`;

export default ({
  logoLink,
  links,
  className,
  collapseBreakpointClass = "lg",
}) => {
  const { translate, language, setLanguage } = useLanguage();
  const defaultLinks = [
    <NavLinks key={1}>
      {translate("language")}:
      <button>
        <Image
          src={language === "en" ? EnglishFlag : SpanishFlag}
          onClick={() => setLanguage(language === "en" ? "es" : "en")}
        />
      </button>
    </NavLinks>,
  ];

  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={logo} alt="logo" />
      PC Precision Repairs
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
      </DesktopNavLinks>
    </Header>
  );
};

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};
